var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toolbarTitle', {
    staticClass: "mb-4",
    attrs: {
      "title": _vm.header ? _vm.header : 'Orders'
    }
  }), _c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "autofocus": _vm.filter.query == null,
      "prepend-inner-icon": "mdi-magnify",
      "label": "Search by order ID...",
      "hint": "Search by Mango order ID or external booking ID",
      "hide-details": "",
      "color": "orange"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.listOrders();
      }
    },
    model: {
      value: _vm.filter.query,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "query", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "filter.query"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "12",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "font-weight-bold",
    attrs: {
      "outlined": "",
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify",
      "label": "Search by customer details...",
      "hint": "Search by customer or passenger details (name, email, phone)",
      "persistent-hint": "",
      "hide-details": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.listOrders();
      }
    },
    model: {
      value: _vm.filter.customer_query,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "customer_query", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "filter.customer_query"
    }
  })], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "12"
    }
  }, [_c('v-menu', {
    ref: "datepicker",
    attrs: {
      "close-on-content-click": false,
      "nudge-left": 70,
      "transition": "scale-transition",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "font-weight-bold",
          attrs: {
            "label": "Order creation date",
            "prepend-inner-icon": "mdi-calendar",
            "readonly": "",
            "outlined": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.filter.dates,
            callback: function ($$v) {
              _vm.$set(_vm.filter, "dates", $$v);
            },
            expression: "filter.dates"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.datepicker,
      callback: function ($$v) {
        _vm.datepicker = $$v;
      },
      expression: "datepicker"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "first-day-of-week": 1,
      "no-title": "",
      "range": "",
      "hide-details": ""
    },
    model: {
      value: _vm.filter.dates,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "dates", $$v);
      },
      expression: "filter.dates"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('v-select', {
    staticClass: "align-stretch font-weight-bold",
    attrs: {
      "outlined": "",
      "items": _vm.tenants,
      "item-text": "name",
      "chips": "",
      "item-value": "id",
      "menu-props": {
        maxHeight: '400'
      },
      "label": "Select tenants",
      "multiple": "",
      "hide-details": "",
      "height": "56",
      "persistent-hint": false
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [_vm.shouldShowChip(index) ? _c('v-chip', [_c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e(), index === 3 ? _c('span', {
          staticClass: "grey--text text-caption"
        }, [_vm._v(" (+" + _vm._s(_vm.selectedTenants.length - _vm.shouldShowChipIndex()) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selectedTenants,
      callback: function ($$v) {
        _vm.selectedTenants = $$v;
      },
      expression: "selectedTenants"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('SearchFilters', {
    ref: "childFilters"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "accent",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.listOrders();
      }
    }
  }, [_vm._v("SEARCH")])], 1)], 1), _c('v-row', [_c('v-col')], 1), !_vm.searchedAtLeastOnce ? _c('h4', {
    staticStyle: {
      "display": "table",
      "margin": "0 auto"
    }
  }, [_vm._v("Start a search to see the orders.")]) : _vm._e(), _vm.searchedAtLeastOnce ? _c('v-data-table', {
    staticClass: "elevation-1 mt-2",
    attrs: {
      "mobile-breakpoint": 1500,
      "headers": _vm.headers,
      "items": _vm.orders,
      "server-items-length": _vm.ordersTotalCount,
      "loading": _vm.loading,
      "items-per-page": _vm.filter.itemsPerPage,
      "options": _vm.filter,
      "footer-props": {
        'items-per-page-options': [30, 45, 100, 500]
      }
    },
    on: {
      "update:options": function ($event) {
        _vm.filter = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: `item.id`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "orderID"
        }, [_c('div', {
          staticClass: "d-flex one-line"
        }, [_c('OrderIDField', {
          attrs: {
            "order": item
          }
        }), _c('OrderIconsField', {
          attrs: {
            "order": item,
            "showPrintableInfo": true,
            "showDistributedInfo": true,
            "showGroupOrderInfo": true,
            "showClaimInfo": true,
            "showArchiveOrder": true,
            "isOverview": true
          }
        })], 1)])];
      }
    }, {
      key: `item.bookings`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return _vm._l(_vm.isOverview ? item.bookingExternalOrderIds : item.bookings, function (booking, i) {
          return _c('div', {
            key: i,
            staticClass: "bookingID"
          }, [_c('div', [_c('h4', {
            staticClass: "grey--text text--darken-4",
            staticStyle: {
              "display": "flex",
              "flex-wrap": "nowrap"
            }
          }, [_c('v-tooltip', {
            attrs: {
              "top": "",
              "open-delay": 10,
              "close-delay": 5
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function (_ref5) {
                var on = _ref5.on,
                  attrs = _ref5.attrs;
                return [_c('v-icon', _vm._g(_vm._b({
                  attrs: {
                    "color": "mango-red",
                    "small": ""
                  },
                  on: {
                    "click": function ($event) {
                      return _vm.copyToClipboard(_vm.isOverview ? booking : booking.externalOrderId);
                    }
                  }
                }, 'v-icon', attrs, false), on), [_vm._v(" mdi-content-copy ")])];
              }
            }], null, true)
          }, [_c('span', [_vm._v("Copy")])]), _c('span', {
            staticClass: "ml-1 font-weight-bold"
          }, [_vm._v(_vm._s(_vm.isOverview ? booking : booking.externalOrderId))])], 1)]), i + 1 !== (_vm.isOverview ? item.bookingExternalOrderIds.length : item.bookings.length) ? _c('v-divider', {
            key: i,
            staticClass: "mr-7"
          }) : _vm._e()], 1);
        });
      }
    }, {
      key: `item.created`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "dateTime"
        }, [_c('dateTimeField', {
          attrs: {
            "date": item.created,
            "type": "dateTime"
          }
        })], 1)];
      }
    }, {
      key: `item.seller`,
      fn: function (_ref7) {
        var _item$seller$userFull;
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "soldBY"
        }, [_c('div', {
          staticClass: "text-truncate font-weight-medium"
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": "",
            "color": "black"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref8) {
              var on = _ref8.on;
              return [_c('v-avatar', _vm._g({
                staticClass: "mr-1",
                attrs: {
                  "width": "20",
                  "height": "20",
                  "tile": "",
                  "min-width": "30"
                }
              }, on), [_c('img', {
                attrs: {
                  "src": 'https://assets.trainplanet.com/wlabel/' + _vm.fixUrl(_vm.isOverview ? item.seller.tenantName : item.seller.tenant.name) + '/logos/favicon-32x32.png'
                }
              })])];
            }
          }], null, true)
        }, [_c('span', {
          staticStyle: {
            "display": "block",
            "text-align": "center"
          }
        }, [_vm._v(_vm._s(_vm.isOverview ? item.seller.tenantName : item.seller.tenant.name))])]), _c('span', [_vm._v(_vm._s((_item$seller$userFull = item.seller.userFullName) !== null && _item$seller$userFull !== void 0 ? _item$seller$userFull : item.seller.user.name))])], 1)])];
      }
    }, {
      key: `item.customer`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item.customer.fullName ? _c('div', {
          staticClass: "customer"
        }, [_c('div', {
          staticClass: "text-truncate font-weight-medium"
        }, [(_vm.isOverview ? item.isBusinessOrder : item.invoiceId || item.customer.category === 'company') ? _c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-domain ")]) : _vm._e(), _vm._v(" " + _vm._s(item.customer.fullName) + " "), item.customer ? _c('div', [_c('v-divider'), item.customer ? _c('div', [_c('div', {
          staticClass: "grey--text text-truncate"
        }, [_c('v-tooltip', {
          attrs: {
            "top": "",
            "open-delay": 10,
            "close-delay": 5
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref10) {
              var on = _ref10.on,
                attrs = _ref10.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "color": "mango-red",
                  "small": ""
                },
                on: {
                  "click": function ($event) {
                    return _vm.copyToClipboard(item.customer.email);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-content-copy ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Copy")])]), _c('span', {
          staticClass: "ml-1 font-weight-medium"
        }, [_vm._v(_vm._s(item.customer.email))])], 1)]) : _vm._e()], 1) : _vm._e()], 1)]) : _c('div', {
          staticClass: "grey--text font-weight-medium"
        }, [_vm._v("N/A")])];
      }
    }, {
      key: `item.refundAmount`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "refunded"
        }, [_c('span', {
          staticClass: "one-line",
          class: _vm.getColorBalance(item.status)
        }, [_vm._v(" " + _vm._s(_vm.isOverview ? item.refundAmountPlainText : item.cancelInformation.amount.plainText) + " ")])])];
      }
    }, {
      key: `item.validityStatus`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "validityHeader"
        }, [_c('ValidationSystems', {
          attrs: {
            "order-id": item.id,
            "validity": item.validity,
            "showTextCaption": false,
            "validity": item.validity
          },
          on: {
            "update:validity": function ($event) {
              return _vm.$set(item, "validity", $event);
            }
          }
        })], 1)];
      }
    }, {
      key: `item.priceTotal`,
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "totalPrice"
        }, [_c('span', {
          staticClass: "font-weight-bold one-line"
        }, [_vm._v(" " + _vm._s(_vm.isOverview ? item.totalPricePlainText : item.calculatedPrice.total.plainText) + " ")])])];
      }
    }, {
      key: `item.isPaid`,
      fn: function (_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "status"
        }, [_c('v-chip', {
          staticClass: "font-weight-bold cliporder",
          class: _vm.getColorStatus(item.status),
          staticStyle: {
            "min-width": "60px",
            "font-size": "12px",
            "min-height": "27px",
            "text-transform": "capitalize"
          },
          attrs: {
            "link": "",
            "x-small": ""
          }
        }, [_vm._v(" " + _vm._s(item.status.replace("_", " ")) + " ")])], 1)];
      }
    }], null, true)
  }) : _vm._e(), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": "",
      "color": "#f4846e"
    }
  }) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "accent",
      "to": {
        name: 'orders-create'
      },
      "fab": "",
      "large": "",
      "fixed": "",
      "dark": "",
      "bottom": "",
      "right": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }