const axios = require("axios");

export default class AccountApi {
  constructor(tenantId, token) {
    this.client = axios.create({
      baseURL: `${process.env.VUE_APP_TRAINPLANET_API_URL_BASE}`,
    });

    this.client.interceptors.request.use((request) => {
      request.headers["Accept-Language"] = "en-SE";
      request.headers["tenantId"] = tenantId;
      request.headers["Content-Type"] = "application/json";
      request.headers["Authorization"] = "Bearer " + token;
      return request;
    });
  }

  getAccount(accountId) {
    return this.client
      .get(`account/?accountId=${accountId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (401 == error.response.data.status) {
          this.refreshToken({
            refreshToken: localStorage.getItem("refresh_token"),
          })
            .then(({ data }) => {})
            .catch((error) => {
              this.hasError = error.response.data.status;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
  }

  refreshToken(data) {
    return this.client.post(`account/refreshToken`, data).then((response) => {
      if (localStorage.getItem("refresh_token")) {
        localStorage.setItem("auth_token", response.data.accessToken);
      }
    });
  }

  registerPassenger(accountId, data) {
    return this.client.post(`account/${accountId}/passenger`, data);
  }

  updatePassenger(id, data, accountId) {
    return this.client.patch(`account/${accountId}/passenger/${id}`, data);
  }

  deletePassenger(accountId, id) {
    return this.client.delete(`account/${accountId}/passenger/${id}`);
  }

  getPassengerList(accountId, filter) {
    return this.client.get(`account/${accountId}/passenger/list`, {
      params: {
        ...filter,
      },
    });
  }

  createPassenger(data, accountId) {
    return this.client.post(`account/${accountId}/passenger?force=true`, data);
  }

  updateAccount(data, accountId) {
    return this.client.patch(`account/${accountId}/update`, data);
  }

  anonymizeAccount(data, accountId) {
    return this.client.patch(`account/${accountId}/anonymize`, data);
  }

  async getPersonalData(accountId) {
    const { data } = await this.client.get(`account/${accountId}/personal-data`);
    return data;
  }

  async getOrders(filter, accountId) {
    const { data, headers } = await this.client.get(`account/${accountId}/orders`, {
      params: {
        ...filter,
      },
    });
    return {
      meta: {
        count: data.meta.total,
      },
      data,
    };
  }

  getPassenger(id) {
    return this.client.get(`account/passenger?passengerId=${id}`);
  }

  createAddress(data, accountId) {
    return this.client.post(`account/${accountId}/address`, data);
  }

  deleteAddress(accountId, id) {
    return this.client.delete(`account/${accountId}/address/${id}`);
  }

  updateAddress(id, data, accountId) {
    return this.client.patch(`account/${accountId}/address/${id}`, data);
  }
}
