<template>
  <v-dialog
    v-if="addressDialog.open"
    :value="addressDialog.open"
    :persistent="loading"
    width="600px"
    class="pa-5"
    @input="closeDialog">
    <v-card>
      <v-toolbar fixed color="titlebg" class="title" dense dark>
        <v-toolbar-title class="ml-2">
          {{ addressDialog.purpose === "create" ? "Create" : "Edit" }} Address
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="pa-6">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="addressPayload.firstName"
              :rules="[rules.required]"
              label="Address Name"
              outlined
              dense
              required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="addressPayload.address"
              :rules="[rules.required]"
              label="Address"
              outlined
              dense
              required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="addressPayload.postalCode" label="ZIP Code" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="addressPayload.city"
              :rules="[rules.required]"
              label="City"
              outlined
              dense
              required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select v-model="addressPayload.country" :items="countries" label="Country" outlined dense></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-center">
            <v-tooltip :disabled="isFormValid" color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="!isFormValid"
                    width="100%"
                    @click="submitAddress">
                    {{ addressDialog.purpose === "create" ? "Create" : "Update" }}
                  </v-btn>
                </div>
              </template>
              <span>Please fill in all required fields.</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AddressListModal",
  props: {
    addressDialog: {
      type: Object,
      default: () => ({}),
    },
    addressPayload: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  computed: {
    isFormValid() {
      return !!this.addressPayload.firstName && !!this.addressPayload.address && !!this.addressPayload.city;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    submitAddress() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped></style>
