<template>
  <div class="mb-0">
    <v-row>
      <v-col
          v-for="(item, index) in items.filter(value => !value.hidden)"
          :key="item.name"
          :lg="getNumberOfCols(index)"
          class="pb-0"
          cols="12"
      >
        <div class="pb-0">
          <div v-if="item.name === 'Seller2'" @click="openSellerDialog"
          >
            <fieldset class="custom-wrapper">
              <legend style="font-size: 12px; margin-left: 9px; color: #979799ff">Select Seller
              </legend>
              <v-row>
                <v-col v-if="shouldShowAllSeller" class="ml-2"
                       position="relative"
                       style="margin-top: 6px; margin-bottom: 10px">
                  <div>All</div>
                </v-col>
                <v-col class="ml-3 pr-0"
                >
                  <v-chip
                      v-for="(item, index) in visibleChips"
                      v-if="shouldShowChip(index)"
                      :key="index"
                      class="custom-chip  mb-2"
                      outlined
                      style="background-color: #E0E0E0 !important; border: transparent !important;"
                  >
                    <v-list-item-avatar size="20" style="margin-right: 2px; margin-top: 5px">
                      <v-icon :disabled="false" color="black">{{ item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-title :style="{ color: `black` }">
                      <h4 style="font-size: 12px">{{ item.fullName ?? item.name }}</h4>
                    </v-list-item-title>
                  </v-chip>
                  <div
                      v-if="shouldShowExtraChip"
                      class="ml-lg-n1"
                      style="font-size: 12px;
                    color: #9f9f9fff;
                    display: inline-block;
                    margin-top: 8px;
                    position: absolute;">
                    (+{{ extraChipCount }})
                  </div>
                </v-col>
              </v-row>
              <div class="mdi-menu-down-icon">
                <v-icon :disabled="false" color="gray">mdi-menu-down</v-icon>
              </div>
            </fieldset>
          </div>
          <v-select
              v-else-if="item.name !== 'Seller'"
              v-model="item.selectedValue"
              :items="item.items"
              :label="item.name"
              class="pb-0"
              hide-details
              item-text="label"
              item-value="value"
              outlined
          ></v-select>
        </div>
      </v-col>
    </v-row>
    <!-- Dialog for "seller" filter -->
    <v-dialog v-model="sellerDialog" max-width="75%">
      <v-card>
        <!-- Toolbar component -->
        <v-toolbar class="title" color="titlebg" dark dense fixed>
          <v-toolbar-title>Seller</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- Main content of the dialog -->
        <div class="pa-7  pb-0  rounded-lg">
          <!-- Grid layout -->
          <v-row>
            <v-col class="pb-0" cols="12">
              <div v-for="item in items" :key="item.name">
                <v-select
                    v-if="item.name === 'Seller'"
                    v-model="item.selectedValue"
                    :items="item.items.filter(subitem => !subitem.hidden)"
                    class="pb-0 "
                    dense
                    hide-details
                    item-text="label"
                    item-value="value"
                    label="Systems Seller"
                    outlined
                    @change="handleSellerChange(item)"
                ></v-select>
              </div>
            </v-col>
            <v-col cols="11/2">
              <!--  v-tab-->
              <div>
                <v-tabs class="mb-1">
                  <v-tab class=" " @click="activeTab = 'users'">
                    <h4>Users</h4>
                  </v-tab>
                  <v-tab class=" " @click="activeTab = 'groups'">
                    <h4>Groups</h4>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="activeTab">
                  <v-tab-item value="users">
                    <div class="">
                      <v-row>
                        <v-col>
                          <v-text-field
                              ref="search"
                              v-model="userSearchQuery"
                              :loading="searching"
                              class=" mt-3 mb-3 rounded-lg"
                              dense
                              full-width
                              hide-details
                              label="Search"
                              outlined
                              prepend-inner-icon="mdi-account-search"
                              single-line
                          ></v-text-field>
                          <h4 class=" mb-0 ">Search Result</h4>
                          <!-- List component for displaying search results -->
                          <v-list dense style="height: 300px; overflow-y: auto">
                            <div v-if="filteredUsers.length > 0">
                              <!-- Loop through filtered users -->
                              <template v-for="item in filteredUsers">
                                <!-- List item for each user -->
                                <v-list-item
                                    :key="item.id"
                                    :disabled="loading"
                                    class=" rounded-lg mb-2 "
                                    dense
                                >
                                  <!-- User avatar -->
                                  <v-list-item-avatar size="20">
                                    <v-icon
                                        :disabled="false"
                                        color="mango-red"
                                        dense
                                        v-text="item.icon"
                                    ></v-icon>
                                  </v-list-item-avatar>
                                  <!-- User name & e-mail -->
                                  <v-list-item-title v-text="item.fullName ?? item.name"/>
                                  <v-list-item-subtitle v-text="item.email"/>
                                  <v-btn
                                      icon
                                      @click="createUserSelection(item)"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </template>
                            </div>
                          </v-list>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="groups">
                    <div>
                      <v-row>
                        <v-col>
                          <v-text-field
                              ref="search"
                              v-model="groupSearchQuery"
                              :loading="searching"
                              class=" mt-3 mb-3 rounded-lg"
                              dense
                              full-width
                              hide-details
                              label="Search"
                              outlined
                              prepend-inner-icon="mdi-account-search"
                              single-line
                          ></v-text-field>
                          <h4 class=" mb-0 ">Search Result</h4>
                          <!-- List component for displaying search results -->
                          <v-list dense style="height: 300px; overflow-y: auto">
                            <div v-if="filteredGroups.length > 0">
                              <!-- Loop through filtered Group -->
                              <template v-for="group in filteredGroups">
                                <!-- List item for each Group -->
                                <v-list-item
                                    :key="group.name"
                                    :disabled="loading"
                                    class=" rounded-lg mb-2"
                                    dense
                                >
                                  <!-- Group avatar -->
                                  <v-list-item-avatar size="20">
                                    <v-icon
                                        :disabled="false"
                                        color="mango-red"
                                        dense
                                        v-text="group.icon"
                                    ></v-icon>
                                  </v-list-item-avatar>
                                  <!-- Group name -->
                                  <v-list-item-title
                                      v-text="group.name"
                                  ></v-list-item-title>
                                  <v-btn
                                      icon
                                      @click="createGroupSelection(group)"
                                  >
                                    <v-icon>mdi-plus</v-icon>
                                  </v-btn>
                                </v-list-item>
                              </template>
                            </div>
                          </v-list>
                        </v-col>
                      </v-row>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </v-col>
            <v-col class="pa-0 ma-0 mt-10 mb-13" cols="1" style="text-align: center;">
              <!-- FIXME make this divider not visible in mobile view -->
              <v-divider
                  class="mt-5"
                  style="border: 1px solid #f4846e !important;"
                  vertical
              />
            </v-col>
            <v-col cols="11/2">
              <div>
                <h4 class="mt-4 " style="text-align: center">Selected Users & Groups
                  ({{ selectedUsersAndGroups.length }})</h4>
                <v-divider class="mt-1 mr-15 ml-15 mb-1 "
                           style="	border: 1px solid #f4846e !important;"/>
                <!-- List component for displaying selected users and groups -->
                <v-list style="height: 300px; overflow-y: auto">
                  <!-- Loop through selected users and groups -->
                  <v-list-item
                      v-for="(selection, i) in selectedUsersAndGroups"
                      :key="selection.id"
                      :style="{
                      background: `#f4846e`,
                      height: `42px`,
                      }"
                      class="list-item shrink rounded-lg mb-2  "
                      style="cursor: pointer;"
                  >
                    <!-- User or group icon -->
                    <v-list-item-avatar size="20">
                      <v-icon
                          :disabled="false"
                          color="white"
                      >{{ selection.icon }}
                      </v-icon>
                    </v-list-item-avatar>

                    <!-- User or group name -->
                    <v-list-item-title
                        :style="{color: `white`,}"
                    >{{ selection.fullName ?? selection.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="selection.email" v-text="selection.email"/>

                    <!-- Remove button for user/group selection -->
                    <v-btn
                        icon
                        @click="removeUserOrGroupSelection(i)"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- Card actions -->
        <v-card-actions class="justify-lg-space-between pb-9 pr-7  pl-7 px-4">
          <!-- Close button -->
          <v-btn color="primary" @click="resetAll">reset</v-btn>
          <v-btn
              :loading="submitLoader"
              color="accent"
              dark
              @click="applyFilters"
          >
            APPLY
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import group_data from "@/views/User/Group/components/group_data";
import {debounce} from "@/util/helpers";
import TrainplanetApi from "@/util/trainplanet.api";
import Trainplanet from "@/util/trainplanet.api";

export default {
  props: [],
  data: function (vm) {
    return {
      items: [
        {
          name: "Payment Status",
          selectedValue: ["paid", true],
          items: [
            {
              label: "All",
              value: null
            },
            {
              label: "Paid",
              value: ["paid", true]
            },
            {
              label: "Not Paid",
              value: ["paid", false]
            }
          ]
        },
        {
          name: "Customer Type",
          selectedValue: null,
          items: [
            {
              label: "All",
              value: null
            },
            {
              label: "Private Customer",
              value: ["privateCustomer", true]
            },
            {
              label: "Company Customer",
              value: ["companyCustomer", true]
            }
          ]
        },
        {
          name: "Printable Status",
          selectedValue: null,
          items: [
            {
              label: "All",
              value: null
            },
            {
              label: "Printable Only",
              value: ["printable", true]
            }
          ]
        },
        {
          name: "Archive Status",
          selectedValue: ["archived", false],
          items: [
            {
              label: "All",
              value: null
            },
            {
              label: "Archived",
              value: ["archived", true]
            },
            {
              label: "Not Archived",
              value: ["archived", false]
            }
          ]
        },
        {
          name: "Seller",
          selectedValue: ["All", null],
          hidden: true,
          items: [
            {
              label: "All",
              value: ["All", null],
            },
            {
              label: "Only Lisa",
              value: ["onlyLisa", true],

            },
            {
              label: "Exclude Lisa",
              value: ["excludeLisa", true],
            },
          ]
        },
        {
          name: "Seller2",
          selectedValue: ["explode", {sellerIds: [], groupIds: []}],
          items: [
            {
              label: "Select Users & Groups",
              selectedValue: ["explode", {sellerIds: [], groupIds: []}],
              hidden: true,
            },
            {
              label: "Printable Only",
              value: ["printable", true]
            }
          ]
        },
      ],
      options: {
        search: null,
        page: 1,
        itemsPerPage: 50,
        total: 0,
      },
      maxVisibleChips: 3,
      activeTab: 'users',
      sellerDialog: false,
      userSearchQuery: "",
      groupSearchQuery: "",
      searching: false,
      submitLoader: false,
      loading: false,
      search: "",
      userList: [],
      groupList: [],
      editItem: JSON.parse(JSON.stringify(group_data)),
      screenWidth: window.innerWidth,
    };
  },
  mounted() {
    this.loading = true;
    this.getUsers();
    this.getGroups();
    this.loading = false;
    window.addEventListener('resize', this.updateScreenWidth);
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    combinedSelections() {
      const selectedSeller = this.getSelectedSellerOption();

      const sellerChip = {
        id: 'seller',
        name: selectedSeller ? selectedSeller.label : 'Unknown Seller',
        icon: selectedSeller ? this.selectedSellerIcon : 'mdi-account',
      };

      const userGroupChips = this.selectedUsersAndGroups.map(selection => ({
        id: selection.id,
        name: selection.fullName ?? selection.name,
        icon: selection.icon,
      }));

      return [sellerChip, ...userGroupChips];
    },
    visibleChips() {
      const selectedSeller2 = this.items.find(item => item.name === 'Seller2').selectedValue[0];
      const filteredChips = this.combinedSelections.filter(chip => {
        return selectedSeller2 !== 'explode' || (chip.name !== 'All' && chip.name !== 'Unknown Seller');
      });
      return filteredChips.slice(0, this.maxVisibleChips);
    },
    extraChipCount() {
      const filteredChips = this.combinedSelections.filter(chip => chip.name !== 'All' && chip.name !== 'Unknown Seller');
      const extraCount = filteredChips.length - this.maxVisibleChips - this.shouldShowChipIndex() + 3;
      return extraCount > 0 ? extraCount : 0;
    },
    shouldShowExtraChip() {
      return this.extraChipCount > 0;
    },
    selectedSellerIcon() {
      const selectedSellerOption = this.getSelectedSellerOption();
      return selectedSellerOption ? 'mdi-robot' : '';
    },
    shouldShowAllSeller() {
      const systemSellerFilter = this.items.find(item => item.name === 'Seller');
      const systemSellerValue = systemSellerFilter.selectedValue[0];

      return (
          this.selectedUsersAndGroups.length === 0 &&
          !(systemSellerValue === 'onlyLisa' || systemSellerValue === 'excludeLisa')
      );
    },
    selectedUsersAndGroups() {
      const selectedItems = [...this.editItem.users, ...this.editItem.groups];
      return selectedItems.map(item => ({
        id: item.id,
        name: item.fullName ?? item.name,
        icon: item.icon,
        email: item.email
      }));
    },
    users() {
      const search = this.search.toLowerCase();
      if (!search) return this.userList;
      return this.userList.filter((item) => {
        const text = item.text.toLowerCase();
        return text.indexOf(search) > -1;
      });
    },
    filteredUsers() {
      const userIds = this.editItem.users.map(user => user.id);
      const filteredUsers = this.userList.filter((user) => !userIds.includes(user.id));
      const loggedInUser = filteredUsers.find(user => user.id === this.user.localAccountId);
      //  if there is a logged-in user in the "Search Result" section of the dialog, move it to the top
      if (loggedInUser) {
        const loggedInUserIndex = filteredUsers.indexOf(loggedInUser);
        if (loggedInUserIndex !== -1) {
          filteredUsers.splice(loggedInUserIndex, 1);
        }
        filteredUsers.unshift(loggedInUser);
      }
      /*if there is not a logged-in user in the filteredUsers of the dialog, add  logged-in user
      in the filteredUsers and  move it to the top*/
      else if (!userIds.includes(this.user.localAccountId) && this.userSearchQuery === "") {
        filteredUsers.unshift({
          id: this.user.localAccountId,
          name: this.user.fullName ?? this.user.name,
          icon: 'mdi-account',
          email: this.user.username
        });
      }
      return filteredUsers;
    },
    groups() {
      const search = this.search.toLowerCase();
      if (!search) return this.groupList;
      return this.groupList.filter((item) => {
        const text = item.text.toLowerCase();
        return text.indexOf(search) > -1;
      });
    },
    filteredGroups() {
      return this.groupList.filter(group => {
        return !this.editItem.groups.some(u => u.id === group.id);
      });
    },
  },
  created() {
    // this gets reset upon log out and after the check below
    const storageItems = JSON.parse(localStorage.getItem("mango_search_filters"));

    if (storageItems) {
      if (!this.searchFiltersInStorageHasSameStructureAsLocal(this.items, storageItems)) {
        localStorage.removeItem("mango_search_filters");
      } else {
        this.items = storageItems;
      }
    }
  },
  watch: {
    editedGroup() {
      if (this.editedGroup) {
        this.editItem = this.editedGroup;
      } else {
        this.editItem = JSON.parse(JSON.stringify(group_data));
      }
    },
    selected() {
      this.search = "";
    },
    userSearchQuery: {
      deep: true,
      handler: debounce(function () {
        this.getUsers();
      }, 1000),
    },
    groupSearchQuery: {
      deep: true,
      handler: debounce(function () {
        this.getGroups();
      }, 1000),
    },
    items: {
      deep: true,
      handler: function (val) {
        if (val) {
          this.saveItemsToStorage();
        }
      }
    },
    sellerDialog(value) {
      const sellerFilter = this.items.find(item => item.name === "Seller2");
      if (value === false && sellerFilter.selectedValue[0] === "explode") {
        sellerFilter.selectedValue[1] = {
          "sellerIds": this.editItem.users.map(user => user.id),
          "groupIds": this.editItem.groups.map(group => group.id)
        };
        this.saveItemsToStorage();
      }
      this.sellerDialog = value;
    },
  },
  methods: {
    /**
     * Returns false if the storage items have different structure than the local items.
     * This is required when an update to local search filters is made
     * and the user has an outdated search filter structure in local storage.
     *
     * @param {object} originalItems
     * @param {object} storageItems
     * @return {boolean}
     */
    searchFiltersInStorageHasSameStructureAsLocal(originalItems, storageItems) {
      for (let i = 0; i < storageItems.length; i++) {
        const storageItem = storageItems[i];
        const originalItem = originalItems[i];

        if (storageItem.name !== originalItem.name) {
          return false;
        }

        for (let j = 0; j < storageItem.items.length; j++) {
          const storageSubItem = storageItem.items[j];
          const originalSubItem = originalItem.items[j];

          if (storageSubItem.label !== originalSubItem.label) {
            return false;
          }

          if (Array.isArray(storageSubItem.value)) {
            if (storageSubItem.value[0] !== originalSubItem.value[0]) {
              return false;
            }
          } else {
            if (storageSubItem.value !== originalSubItem.value) {
              return false;
            }
          }
        }
      }

      return true;
    },
    saveItemsToStorage() {
      localStorage.setItem("mango_search_filters", JSON.stringify(this.items));
    },
    getMaxChipsForScreenWidth(width) {
      if (width >= 1830) return 3;
      if (width >= 1410) return 2;
      if (width >= 1260) return 1;
      return 3;
    },
    shouldShowChip(index) {
      const maxChips = this.getMaxChipsForScreenWidth(this.screenWidth);
      return index < maxChips;
    },
    shouldShowChipIndex() {
      return this.getMaxChipsForScreenWidth(this.screenWidth);
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    getSelectedSellerOption() {
      const sellerFilter = this.items.find(item => item.name === 'Seller');
      const selectedSellerValue = sellerFilter.selectedValue[0];
      return sellerFilter.items.find(item => item.value[0] === selectedSellerValue);
    },
    async applyFilters() {
      this.sellerDialog = false;
    },
    removeUserOrGroupSelection(index) {
      const removedItem = this.selectedUsersAndGroups.splice(index, 1)[0];
      const userIndex = this.editItem.users.findIndex(user => user.id === removedItem.id);
      const groupIndex = this.editItem.groups.findIndex(group => group.id === removedItem.id);
      if (userIndex !== -1) {
        this.editItem.users.splice(userIndex, 1);
        this.userList.push({
          id: removedItem.id,
          name: removedItem.fullName ?? removedItem.name,
          tenants: removedItem.tenants,
          icon: removedItem.icon,
          email: removedItem.email
        });
      } else if (groupIndex !== -1) {
        this.editItem.groups.splice(groupIndex, 1);
        this.groupList.push({
          id: removedItem.id,
          name: removedItem.fullName ?? removedItem.name,
          tenants: removedItem.tenants,
          icon: removedItem.icon,
        });
      }
    },
    createUserSelection(item) {
      const isUserExist = this.editItem.users.some(user => user.id === item.id);
      if (!isUserExist) {
        this.editItem.users.push({
          id: item.id,
          name: item.fullName ?? item.name,
          icon: item.icon,
          email: item.email
        });
        this.userList = this.userList.filter(user => user.id !== item.id);
      }
    },
    createGroupSelection(group) {
      const isGroupExist = this.editItem.groups.some(g => g.id === group.id);
      if (!isGroupExist) {
        this.editItem.groups.push({
          id: group.id,
          name: group.name,
          icon: group.icon
        });
        this.groupList = this.groupList.filter(g => g.id !== group.id);
      }
    },
    closeDialog() {
      this.$emit("onClose");
      this.editItem = JSON.parse(JSON.stringify(group_data));
      this.$emit("onClose");
      this.sellerDialog = false;
    },
    next() {
      this.loading = true;
      setTimeout(() => {
        this.search = "";
        this.selected = [];
        this.loading = false;
      }, 2000);
    },
    async getUsers() {
      this.searching = true;
      const res = await TrainplanetApi.getUsers(this.userSearchQuery);
      this.userList = res.users.map((item) => ({
        ...item,
        name: item.fullName ?? item.name,
        tenants: item.tenants,
        icon: "mdi-account",
      }));
      this.searching = false;
    },
    async getGroups() {
      this.searching = true;
      const res = await Trainplanet.getGroups({
        q: this.groupSearchQuery,
      });
      this.groupList = res.groups.map((item) => ({
        ...item,
        name: item.name,
        tenants: item.tenants,
        icon: "mdi-account-group",
      }));
      this.searching = false;
    },
    handleSellerChange(item) {
      if (item.name === 'Seller') {
        if (item.selectedValue[0] === 'onlyLisa') {
          item.selectedValue = ['onlyLisa', true];
        } else if (item.selectedValue[0] === 'excludeLisa') {
          item.selectedValue = ['excludeLisa', true];
        } else if (item.selectedValue[0] === 'explode') {
          item.selectedValue = ['explode', {sellerIds: [], groupIds: []}];
        }
      }
    },
    openSellerDialog() {
      this.sellerDialog = true;
    },
    resetSellerSelect() {
      const sellerFilter = this.items.find(item => item.name === 'Seller');
      sellerFilter.selectedValue = ['explode', {sellerIds: [], groupIds: []}];
      this.handleSellerChange(sellerFilter);
    },
    resetAll() {
      const sellerFilter = this.items.find(item => item.name === "Seller");
      sellerFilter.selectedValue[1] = {
        sellerIds: [],
        groupIds: []
      };
      while (this.selectedUsersAndGroups.length > 0) {
        this.removeUserOrGroupSelection(0);
      }
      this.resetSellerSelect();
    },
    getNumberOfCols(index) {
      const columnDistribution = [2, 2, 2, 2, 4];
      return columnDistribution[index % columnDistribution.length];
    },
  }
};
</script>

<style scoped>
.v-toolbar__content {
  width: 100% !important;
  background: red !important;
}

.custom-wrapper {
  border: 1px solid #9A9A9C;
  border-radius: 5px;
  overflow: hidden;
  padding: 1px 1px 3px 1px;
  margin-top: -8px;
  display: flex;
}

.custom-chip {
  color: gray;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  color: black;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 8px;
  margin-left: 1px;
}

.mdi-menu-down-icon {
  margin-right: 10px;
}

.custom-chip:hover {
  background-color: darkgrey;
}

.custom-wrapper:hover {
  border-color: black;
}

.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  width: 100% !important;
  background-color: red !important;
}
</style>
