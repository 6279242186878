var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px",
      "margin-bottom": "10px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content flex flex-row justify-space-between",
    staticStyle: {
      "height": "50px",
      "padding": "25px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title"
  }, [_c('h4', {
    staticClass: "black--text"
  }, [_vm._v(_vm._s("Saved Addresses"))])]), _c('v-btn', {
    staticStyle: {
      "height": "40px",
      "font-size": "13px"
    },
    attrs: {
      "color": "primary",
      "disabled": _vm.loading || _vm.loadingInner
    },
    on: {
      "click": _vm.createAddress
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-map-marker")]), _vm._v("Create address ")], 1)], 1)])]), _c('v-data-table', {
    staticClass: "elevation-1 mt-2",
    attrs: {
      "loading": _vm.loading || _vm.loadingInner,
      "mobile-breakpoint": 1500,
      "headers": _vm.headerAddresses,
      "items": _vm.addresses,
      "footer-props": {}
    },
    scopedSlots: _vm._u([{
      key: `item.firstName`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.firstName))])];
      }
    }, {
      key: `item.address`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.address
          }
        })];
      }
    }, {
      key: `item.postalCode`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.postalCode
          }
        })];
      }
    }, {
      key: `item.city`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": item.city
          }
        })];
      }
    }, {
      key: `item.country`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('copyToClipboardField', {
          attrs: {
            "item": _vm.countryNameMap[item.country]
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', [_c('v-btn', {
          attrs: {
            "icon": "",
            "disabled": _vm.loading || _vm.loadingInner
          },
          on: {
            "click": function ($event) {
              return _vm.editAddress(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "third"
          }
        }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          attrs: {
            "icon": "",
            "disabled": _vm.loading || _vm.loadingInner
          },
          on: {
            "click": function ($event) {
              return _vm.deleteAddress(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      }
    }], null, true)
  })], 1), _c('AddressListModal', {
    attrs: {
      "address-dialog": _vm.addressDialog,
      "address-payload": _vm.addressPayload,
      "countries": _vm.allCountries,
      "loading": _vm.loadingInner
    },
    on: {
      "close": function ($event) {
        return _vm.clearDialog();
      },
      "submit": _vm.submitAddress
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }