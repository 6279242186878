<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12">
        <div style="position: relative">
          <header
            class="theme--light v-navigation-drawer elevation-1"
            style="height: 50px; border-radius: 5px; margin-bottom: 10px">
            <div class="v-toolbar__content flex flex-row justify-space-between" style="height: 50px; padding: 25px">
              <div class="v-toolbar__title">
                <h4 class="black--text">{{ header ? header : "Saved Passengers" }}</h4>
              </div>
              <v-btn
                color="primary"
                v-if="haveActions"
                @click="
                  isAdd = true;
                  passengerDialog = true;
                  selectedPassenger = {};
                "
                style="height: 40px; font-size: 13px">
                <v-icon class="mr-3">mdi-account</v-icon> Create Passenger</v-btn
              >
            </div>
          </header>
        </div>
        <v-data-table
          :loading="loading || loadingInner"
          :mobile-breakpoint="1500"
          :headers="headersPassenger"
          :items="passenger"
          class="elevation-1 mt-2"
          :footer-props="{}">
          <template v-slot:[`item.firstName`]="{ item }">
            <div class="orderID">
              <div class="d-flex one-line">
                <span>{{ item.firstName }} {{ item.lastName }}</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <copyToClipboardField :item="item.email"></copyToClipboardField>
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <copyToClipboardField :item="item.phone"></copyToClipboardField>
          </template>

          <template v-slot:[`item.countryOfResidence`]="{ item }">
            <div class="orderID">
              <div class="d-flex one-line">
                <span>{{ titleize(item.countryOfResidence) }}</span>
              </div>
            </div>
          </template>
          <template v-if="haveActions" v-slot:[`item.actions`]="{ item }">
            <div>
              <v-btn
                icon
                @click="
                  isAdd = false;
                  passengerDialog = true;
                  choosePassenger(item);
                ">
                <v-icon color="third">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="
                  dialogDelete = true;
                  choosePassenger(item);
                "
                icon>
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>

      <v-dialog
        v-model="passengerDialog"
        v-if="passengerDialog"
        width="1000px"
        class="pa-5"
        @click:outside="passengerDialog = false">
        <v-card>
          <v-toolbar fixed color="titlebg" class="title" dense dark>
            <v-toolbar-title class="ml-2"
              >Passenger <span v-if="!isAdd">Update</span> <span v-else>Create</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="
                passengerDialog = false;
                selectedPassenger = {};
              ">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <passengerDetail
            v-on:close="
              passengerDialog = false;
              savedPassengers = {};
              getToken();
            "
            v-if="selectedPassenger && selectedPassenger != 'x'"
            :accountId="accountId"
            :passenger="selectedPassenger"
            :isAdd="isAdd" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="800px">
        <v-card class="delete-dialog">
          <v-card-title class="text-h5">Are you sure you want to delete this passenger?</v-card-title>
          <v-card>
            <v-row class="mt-5 mb-5">
              <v-col
                style="display: flex; flex-direction: column; gap: 0.5rem; align-items: center"
                cols="12"
                lg="3"
                md="6"
                sm="6"
                xs="12">
                <h4 class="accent--text">First Name</h4>
                <div>{{ selectedPassenger.firstName }}</div>
              </v-col>

              <v-col
                style="display: flex; flex-direction: column; gap: 0.5rem; align-items: center"
                cols="12"
                lg="3"
                md="6"
                sm="6"
                xs="12">
                <h4 class="accent--text">Last Name</h4>
                <div>{{ selectedPassenger.lastName }}</div>
              </v-col>

              <v-col
                style="display: flex; flex-direction: column; gap: 0.5rem; align-items: center"
                cols="12"
                lg="3"
                md="6"
                sm="6"
                xs="12">
                <h4 class="accent--text">Email</h4>
                <div>{{ selectedPassenger.email }}</div>
              </v-col>

              <v-col
                cols="12"
                lg="3"
                md="6"
                sm="6"
                xs="12"
                style="display: flex; flex-direction: column; gap: 0.5rem; align-items: center">
                <h4 class="accent--text">Country of Residance</h4>
                <div>{{ selectedPassenger.countryOfResidence }}</div>
              </v-col>
            </v-row>
          </v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogDelete = false"> Cancel </v-btn>
            <v-btn color="accent" @click="deletePassenger">
              <span v-show="!loading || loadingInner"> Delete </span>
              <div v-show="loading || loadingInner" class="mng-loader"></div>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import copyToClipboardField from "@/components/common/copyToClipboardField";
import AccountApi from "@/services/account.api";
import passengerDetail from "../passengerAddOrUpdate.vue";

export default {
  name: "passenger-list",
  components: {
    copyToClipboardField,
    passengerDetail,
  },
  props: {
    accountId: "",
    header: "",
    loading: false,
    passenger: [],
    tenant: "",
    token: "",
    haveActions: false,
  },
  computed: {
    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },
  },
  emit: ["change"],
  data() {
    return {
      loadingInner: false,
      passengerDialog: "",
      selectedPassenger: "",
      isAdd: false,
      dialogDelete: false,
      headersPassenger: [],
    };
  },
  created() {
    if (this.haveActions) {
      this.headersPassenger = [
        {
          text: "Full Name",
          value: "firstName",
          sortable: false,
          width: 250,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
        },
        {
          text: "Birthdate",
          value: "birthdate",
          sortable: false,
        },
        {
          text: "Country",
          value: "countryOfResidence",
          sortable: false,
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
        },
      ];
    } else {
      this.headersPassenger = [
        {
          text: "Full Name",
          value: "firstName",
          sortable: false,
          width: 250,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
        },
        {
          text: "Birthdate",
          value: "birthdate",
          sortable: false,
        },
        {
          text: "Country",
          value: "countryOfResidence",
          sortable: false,
        },
      ];
    }
  },
  methods: {
    deletePassenger() {
      this.loadingInner = true;

      this.AccountModel.deletePassenger(this.accountId, this.selectedPassenger.id)
        .then(({ data }) => {
          this.$store.dispatch("success", "Passenger Deleted Successfuly");
          this.dialogDelete = false;
          this.$emit("change");
        })
        .catch((error) => {
          this.$store.dispatch("error", error);
        })
        .finally(() => {
          this.loadingInner = false;
        });
    },
    titleize(value) {
      if (value) {
        value = value.toLowerCase();
        return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
      }
    },
    choosePassenger(item) {
      this.selectedPassenger = item;
    },
    async getToken() {
      this.$emit("change");
    },
  },
};
</script>
