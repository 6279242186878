<template>
  <div>
    <v-row class="mt-4">
      <v-col cols="12">
        <div style="position: relative">
          <header
            class="theme--light v-navigation-drawer elevation-1"
            style="height: 50px; border-radius: 5px; margin-bottom: 10px">
            <div class="v-toolbar__content flex flex-row justify-space-between" style="height: 50px; padding: 25px">
              <div class="v-toolbar__title">
                <h4 class="black--text">{{ "Saved Addresses" }}</h4>
              </div>
              <v-btn
                color="primary"
                style="height: 40px; font-size: 13px"
                @click="createAddress"
                :disabled="loading || loadingInner">
                <v-icon class="mr-3">mdi-map-marker</v-icon>Create address
              </v-btn>
            </div>
          </header>
        </div>
        <v-data-table
          :loading="loading || loadingInner"
          :mobile-breakpoint="1500"
          :headers="headerAddresses"
          :items="addresses"
          class="elevation-1 mt-2"
          :footer-props="{}">
          <template v-slot:[`item.firstName`]="{ item }">
            <span class="font-weight-medium">{{ item.firstName }}</span>
          </template>

          <template v-slot:[`item.address`]="{ item }">
            <copyToClipboardField :item="item.address"></copyToClipboardField>
          </template>

          <template v-slot:[`item.postalCode`]="{ item }">
            <copyToClipboardField :item="item.postalCode"></copyToClipboardField>
          </template>

          <template v-slot:[`item.city`]="{ item }">
            <copyToClipboardField :item="item.city"></copyToClipboardField>
          </template>

          <template v-slot:[`item.country`]="{ item }">
            <copyToClipboardField :item="countryNameMap[item.country]"></copyToClipboardField>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <div>
              <v-btn icon :disabled="loading || loadingInner" @click="editAddress(item)">
                <v-icon color="third">mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon :disabled="loading || loadingInner" @click="deleteAddress(item)">
                <v-icon color="primary">mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>

      <AddressListModal
        :address-dialog="addressDialog"
        :address-payload="addressPayload"
        :countries="allCountries"
        :loading="loadingInner"
        @close="clearDialog()"
        @submit="submitAddress" />
    </v-row>
  </div>
</template>
<script>
import copyToClipboardField from "@/components/common/copyToClipboardField";
import AccountApi from "@/services/account.api";
import countries from "@/static/countries.json";
import AddressListModal from "./AddressListModal.vue";

export default {
  name: "AddressList",
  components: {
    copyToClipboardField,
    AddressListModal,
  },
  props: {
    accountId: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    addresses: {
      type: Array,
      default: () => [],
    },
    tenant: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  emit: ["change"],
  data() {
    return {
      loadingInner: false,
      addressDialog: { open: false, purpose: "create" },
      addressPayload: {},
      allCountries: countries,

      headerAddresses: [
        {
          text: "Address Name",
          value: "firstName",
          sortable: false,
          width: 250,
        },
        {
          text: "Street Address",
          value: "address",
          sortable: false,
        },
        {
          text: "ZIP Code",
          value: "postalCode",
          sortable: false,
        },
        {
          text: "City",
          value: "city",
          sortable: false,
        },
        {
          text: "Country",
          value: "country",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: 120,
        },
      ],
    };
  },
  computed: {
    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },
    countryNameMap() {
      return countries.reduce((map, country) => {
        map[country.value] = country.text;
        return map;
      }, {});
    },
  },
  methods: {
    deleteAddress(address) {
      this.$confirm("Are you sure you want to delete this address?", {
        color: "error",
        title: "Delete Address",
      })
        .then((isConfirmed) => {
          if (!isConfirmed) return;

          this.loadingInner = true;

          this.AccountModel.deleteAddress(this.accountId, address.id)
            .then(() => {
              this.$store.dispatch("success", "Address deleted successfully.");
            })
            .finally(() => {
              this.$emit("change");
              this.loadingInner = false;
            });
        })
        .catch((err) => {
          this.$store.dispatch("error", err.response.data.message || err.message);
        });
    },
    createAddress() {
      this.addressDialog = {
        open: true,
        purpose: "create",
      };
    },
    editAddress(address) {
      this.addressPayload = { ...address };
      this.addressDialog = {
        open: true,
        purpose: "edit",
      };
    },
    submitAddress() {
      this.loadingInner = true;

      if (this.addressDialog.purpose === "create") {
        this.AccountModel.createAddress(this.addressPayload, this.accountId).then(() => {
          this.$store.dispatch("success", "Address created successfully.");
          this.$emit("change");
          this.loadingInner = false;
          this.clearDialog();
        });
      } else {
        this.AccountModel.updateAddress(this.addressPayload.id, this.addressPayload, this.accountId).then(() => {
          this.$store.dispatch("success", "Address edited successfully.");
          this.$emit("change");
          this.loadingInner = false;
          this.clearDialog();
        });
      }
    },
    clearDialog() {
      this.addressDialog = { open: false };
      this.addressPayload = {};
    },
  },
};
</script>
