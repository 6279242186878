<template>
  <div style="margin-top: 2rem !important">
    <v-form @submit.prevent="updateAccount" ref="updateForm">
      <v-row>
        <v-col cols="12" lg="3" md="12" sm="6" xs="12" class="pb-0">
          <v-text-field
            :rules="[rules.required]"
            :disabled="!isUpdate"
            v-model.trim="form.firstName"
            outlined
            clearable
            class="font-weight-bold"
            label="First Name"
            hide-details
            color="orange"></v-text-field>
        </v-col>

        <v-col cols="12" lg="3" md="12" sm="6" xs="12" class="pb-0">
          <v-text-field
            :disabled="!isUpdate"
            v-model.trim="form.lastName"
            outlined
            clearable
            class="font-weight-bold"
            label="Last Name"
            hide-details
            color="orange"></v-text-field>
        </v-col>

        <v-col cols="12" lg="3" md="12" sm="6" xs="12" class="pb-0">
          <v-text-field
            :rules="[rules.required]"
            :disabled="!isUpdate"
            v-model.trim="form.email"
            outlined
            clearable
            class="font-weight-bold"
            label="Email"
            hide-details
            color="orange"></v-text-field>
        </v-col>

        <v-col cols="12" lg="3" md="12" sm="12" xs="12" class="pb-0">
          <VuePhoneNumberInput
            :rules="[rules.required]"
            :disabled="!isUpdate"
            :default-country-code="selectedCountryCode"
            @update="logPhone"
            :error="!phoneValid"
            v-model="form.phone"
            :clearable="isUpdate"
            style="height: 50px" />
        </v-col>

        <v-col cols="12" lg="4" md="12" sm="6" xs="12" class="pb-0">
          <v-btn type="button" :disabled="!isUpdate" color="accent" style="height: 56px; width: 100%" @click="uuidv4"
            >Generate Password</v-btn
          >
        </v-col>

        <v-col cols="12" lg="4" md="12" sm="6" xs="12" class="pb-0" style="position: relative">
          <v-text-field
            :disabled="!isUpdate"
            :append-icon="passEye ? 'mdi-eye' : 'mdi-eye-off'"
            v-model.trim="form.passwords.newPassword"
            outlined
            clearable
            class="font-weight-bold"
            label="New password"
            hide-details
            color="orange"
            :type="passEye ? 'text' : 'password'"
            @click:append="passEye = !passEye"></v-text-field>

          <password-control
            v-on:done="isDone = $event"
            :ifCondition="this.form.passwords.newPassword && this.form.passwords.newPassword != ''"
            :password="this.form.passwords.newPassword"
            :passwordConfirm="confirmPassword" />
        </v-col>

        <v-col cols="12" lg="4" md="12" sm="6" xs="12" class="pb-0">
          <v-text-field
            :disabled="!isUpdate"
            :append-icon="confirmEye ? 'mdi-eye' : 'mdi-eye-off'"
            v-model.trim="confirmPassword"
            outlined
            clearable
            class="font-weight-bold"
            label="Confirm New Pasword"
            hide-details
            color="orange"
            :type="confirmEye ? 'text' : 'password'"
            @click:append="confirmEye = !confirmEye"></v-text-field>
        </v-col>

        <v-col cols="12" lg="8" md="8" sm="6" xs="12" class="pb-0">
          <div class="white elevation-1 rounded pa-5 pt-2 mt-2">
            <v-row>
              <v-col>
                <h4 class="mb-1 mt-3">Message Internal</h4>
                <vue-editor
                  class="vue-editor"
                  :disabled="!isUpdate"
                  :editor-toolbar="customToolbar"
                  v-model="form.internalMessage"
                  style="background-color: #fff6d9">
                </vue-editor>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="4"
          md="4"
          sm="6"
          xs="12"
          class="pb-0 d-flex"
          style="justify-content: space-between; align-items: center; gap: 10px; flex-direction: column">
          <div style="display: flex; flex-direction: column; gap: 40px">
            <div style="margin-top: 50px">
              <v-select
                :disabled="!isUpdate"
                outlined
                class="align-stretch font-weight-bold"
                v-model="form.tenantIds"
                :items="tenants"
                item-text="name"
                chips
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Select tenants"
                multiple
                hide-details
                :persistent-hint="false">
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="shouldShowChip(index)">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 3" class="grey--text text-caption">
                    (+{{ form.tenantIds.length - shouldShowChipIndex() }})
                  </span>
                </template>
              </v-select>
            </div>
            <div class="d-flex align-items-center" style="align-items: center; gap: 10px">
              <v-checkbox
                :disabled="!isUpdate"
                v-model.trim="form.isLocked"
                outlined
                clearable
                class="font-weight-bold"
                style="margin: 0"
                label="Account Locked"
                hide-details
                color="orange"></v-checkbox>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span
                  >This account belongs to an authorized employee of a company. Thus, it is locked for changes and the
                  account owner can not update its details. However, you can update account details from Mango
                  nonetheless.</span
                >
              </v-tooltip>
            </div>
          </div>

          <v-btn type="submit" :disabled="dynamicDisabled" color="primary" style="height: 56px; width: 100%">
            <span v-show="!loading"> Update </span>
            <div v-show="loading" class="mng-loader"></div>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import passwordControl from "@/components/common/PasswordController";
import dateTimeField from "@/components/common/dateTimeField";
import toolbarTitle from "@/components/common/toolbarTitle";
import router from "@/router";
import AccountApi from "@/services/account.api";
import store from "@/store";
import ValidationSystems from "@/views/Orders/Order/ValidationSystems.vue";
import CountryList from "country-list-with-dial-code-and-flag";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { VueEditor } from "vue2-editor";
import { mapGetters } from "vuex";

export default {
  components: {
    dateTimeField,
    toolbarTitle,
    ValidationSystems,
    VueEditor,
    passwordControl,
    VuePhoneNumberInput,
  },
  emits: ["success"],
  props: { isUpdate: false, account: "" },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ color: [] }],
      ],
      form: {
        tenantIds: [],
        lastName: "",
        firstName: "",
        phone: "",
        isLocked: "",
        email: this.account.email,
        passwords: {
          newPassword: "",
        },
        internalMessage: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      tenant: "",
      passEye: false,
      confirmEye: false,
      confirmPassword: "",
      hasError: 0,
      loading: false,
      allTenants: [],
      successMsg: false,
      step: 1,
      tenantId: "",
      token: "",
      isDone: false,
      selectedCountryCode: "SE",
      phoneValid: true,
    };
  },
  created() {
    CountryList, this.getTenant();
    this.getToken();
  },
  watch: {
    account: {
      handler: function f(val) {
        this.form.isLocked = this.account.isLocked;
        this.form.lastName = this.account.lastName;
        this.form.firstName = this.account.firstName;
        this.form.email = this.account.email;
        this.form.tenantIds = this.account.tenants;
        this.form.phone = this.account.phone;
        this.form.internalMessage = this.account.internalMessage;
        if (this.account.phone) {
          this.selectedCountryCode = this.account.phone.split(" ")[0];
          this.selectedCountryCode = "+" + this.selectedCountryCode.substring(1);
          this.form.phone = this.form.phone.split(" ")[1];
          this.selectedCountryCode = window.CountryList.findByDialCode(this.selectedCountryCode)[0].data.code;
        }
      },
    },
    isUpdate: {
      handler: function f(val) {
        this.form.isLocked = this.account.isLocked;
        this.form.lastName = this.account.lastName;
        this.form.firstName = this.account.firstName;
        this.form.email = this.account.email;
        this.form.tenantIds = this.account.tenants;
        this.form.phone = this.account.phone;

        if (this.account.phone) {
          this.selectedCountryCode = this.account.phone.split(" ")[0];
          this.selectedCountryCode = "+" + this.selectedCountryCode.substring(1);
          this.form.phone = this.form.phone.split(" ")[1];
          this.selectedCountryCode = window.CountryList.findByDialCode(this.selectedCountryCode)[0].data.code;
        }
      },
    },

    "form.tenantIds": {
      handler: function f(val) {},
    },
  },
  methods: {
    logPhone(payload) {
      this.selectedCountryCode = payload.countryCode;
      if (this.form.phone) {
        this.phoneValid = payload.isValid;
      } else {
        this.phoneValid = true;
      }
    },
    getColorBalance(status) {
      switch (status) {
        case "completed":
          return "grey--text  font-weight-bold";
        case "refunded":
          return "mango-red  font-weight-bold";
        case "partially_refunded":
          return "mango-orange  font-weight-bold";
        default:
          return "grey--text  font-weight-bold";
      }
    },
    getColorStatus(status) {
      switch (status) {
        case "completed":
          return "mango-green-status";
        case "refunded":
          return "mango-red-status ";
        case "partially_refunded":
          return "mango-orange-status ";
        case "pending":
          return "mango-gray-status  font-weight-bold";
        default:
          return "mango-orange-status ";
      }
    },
    getMaxChipsForScreenWidth(width) {
      if (width >= 1830) return 3;
      if (width >= 1410) return 2;
      if (width >= 1260) return 1;
      return 3;
    },
    shouldShowChip(index) {
      const maxChips = this.getMaxChipsForScreenWidth(this.screenWidth);
      return index < maxChips;
    },
    shouldShowChipIndex() {
      return this.getMaxChipsForScreenWidth(this.screenWidth);
    },
    uuidv4() {
      let result = "";
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 12) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.form.passwords.newPassword = result;
      this.confirmPassword = result;
      var number = /[0-9]/;
      var isNumber = number.test(result);
      if (isNumber) {
        this.passEye = true;
        this.confirmEye = true;
      } else {
        this.uuidv4();
      }
    },

    async getTenant() {
      const res = await this.$store.dispatch("auth/getUser");
    },
    async getToken() {
      this.token = await store.dispatch("auth/acquireToken", router.history.current.meta.scopes);
    },
    updateAccount() {
      if (this.$refs.updateForm.validate() && this.phoneValid) {
        this.loading = true;
        if (this.isDone && this.form.firstName) {
          var sendedObject = {};
          var phoneString = "";
          if (this.form.phone) {
            var dialCode = window.CountryList.findOneByCountryCode(this.selectedCountryCode).data.dial_code;
            phoneString = dialCode + " " + this.form.phone.replace(/\s/g, "");
          }
          if (this.form.passwords.newPassword) {
            sendedObject = {
              internalMessage: this.form.internalMessage,
              phone: phoneString ? phoneString : "",
              tenantIds: this.form.tenantIds,
              email: this.form.email,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              password: this.form.passwords.newPassword,
              isLocked: this.form.isLocked,
            };
          } else {
            sendedObject = {
              internalMessage: this.form.internalMessage,
              phone: phoneString ? phoneString : "",
              tenantIds: this.form.tenantIds,
              email: this.form.email,
              firstName: this.form.firstName,
              lastName: this.form.lastName,
              isLocked: this.form.isLocked,
            };
          }
          this.AccountModel.updateAccount(sendedObject, this.$route.params.id)
            .then(({ data }) => {
              this.$store.dispatch("success", "Account Updated Successfuly");
              this.form.passwords.newPassword = "";
              this.confirmPassword = "";
              this.$emit("success");
            })
            .catch((error) => {
              this.hasError = error.response.data.status;
              this.$store.dispatch("error", error.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
          if (!this.form.firstName) {
            this.$store.dispatch("error", "First name is required area");
          } else {
            this.$store.dispatch("error", "Password is not correct type");
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),

    AccountModel() {
      return new AccountApi(this.tenant, this.token);
    },
    dynamicDisabled() {
      if (!this.isUpdate) return true;
      return !this.isDone;
    },
  },
};
</script>
<style>
.password-box img {
  width: 15px;
  height: 15px;
}

.password-box .item {
  align-items: center;
  margin-top: 1rem;
  font-size: 10px !important;
  display: flex;
  align-items: center;
  gap: 10px;
}

.password-box {
  border: 1px solid rgba(0, 0, 0, 0.589);
}

@media (min-width: 900px) {
  .register {
    padding: 0 100px;
  }

  .register-form {
    width: 80%;
  }
}

.password-item {
  font-size: 10px;
}

.password-box {
  padding: 1rem 1rem;
}

.ql-container.ql-disabled {
  background-color: rgb(194, 194, 194) !important;
}

.quillWrapper:has(> .ql-container.ql-disabled) {
  background-color: rgb(194, 194, 194) !important;
}
</style>
